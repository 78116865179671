import React from "react"
import { graphql, Link } from "gatsby"
import {
  buildScreenshotLink,
  portableToPlainText,
  calculateTimeToRead,
  generatePath,
  generateNameWithHonorific,
} from "../lib/helpers"
import PortableTextRenderer from "../components/PortableTextRenderer"
import WebsiteMeta from "../components/WebsiteMeta"
import ListElement from "../components/ListElement"
import FadeIn from "../components/FadeIn"
import styled from "styled-components"

export default function sanityEditionTemplate({ data, pageContext }) {
  const edition = data.sanityEdition
  const articles = data.sanityArticles

  // Generate social media preview
  const { siteUrl } = data.site.siteMetadata
  const { currentSlug } = pageContext
  const buildTimeUrl = `${siteUrl}${currentSlug}`
  const buildTimeUrlScreenshot = `${buildTimeUrl}/screenshot`
  const metaImage = buildScreenshotLink(buildTimeUrlScreenshot)

  return (
    <>
      <WebsiteMeta
        title={edition.name}
        description={`Check out the ${edition.name} edition of the Priory Press!`}
        image={metaImage}
        imageDimensions={[1200, 630]}
      />
      <Edition>
        <FadeIn direction="up">
          <PageTitle>{edition.name} Edition</PageTitle>
        </FadeIn>
        <FadeIn direction="down" delay={250}>
          <Subheader>
            Released: <span>{edition.release}</span>
            {edition.status === "archived" && (
              <Archived aria-label="This edition has been archived">
                Archived
              </Archived>
            )}
          </Subheader>
        </FadeIn>

        {edition._rawDescription && (
          <FadeIn direction="up" delay={350}>
            <Description>
              <Note2>
                <PortableTextRenderer text={edition._rawDescription} />
              </Note2>
            </Description>
          </FadeIn>
        )}

        <FadeIn direction="up" delay={500}>
          <Articles>
            {articles.edges.map(({ node }, index) => (
              <ListElement
                link={generatePath(node.slug.current, "article")}
                title={node.title}
                line2={generateNameWithHonorific(
                  node.author.name,
                  node.author.honorific
                )}
                extras2={` — ${calculateTimeToRead(
                  portableToPlainText(node._rawContent)
                )} min. read`}
                index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                key={node._id}
              />
            ))}
          </Articles>
        </FadeIn>

        {edition.editorsInChief.length > 0 && (
          <FadeIn direction="up" delay={750}>
            <SectionTitle>
              {edition.editorsInChief.length === 1
                ? "Editor-in-Chief"
                : "Editors-in-Chief"}
            </SectionTitle>
            <People>
              {edition.editorsInChief.map((node, index) => (
                <Link
                  to={generatePath(node.slug.current, "person")}
                  index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                  key={node._id}
                  className="custom"
                >
                  <Person>
                    <h3>
                      {generateNameWithHonorific(node.name, node.honorific)}
                    </h3>
                  </Person>
                </Link>
              ))}
            </People>
          </FadeIn>
        )}

        {edition.editors.length > 0 && (
          <FadeIn direction="up" delay={750}>
            <SectionTitle>
              {edition.editors.length === 1 ? "Editor" : "Editors"}
            </SectionTitle>
            <People>
              {edition.editors.map((node, index) => (
                <Link
                  to={generatePath(node.slug.current, "person")}
                  index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                  key={node._id}
                  className="custom"
                >
                  <Person>
                    <h3>
                      {generateNameWithHonorific(node.name, node.honorific)}
                    </h3>
                  </Person>
                </Link>
              ))}
            </People>
          </FadeIn>
        )}

        {edition.facultyAdvisors.length > 0 && (
          <FadeIn direction="up" delay={750}>
            <SectionTitle>
              {edition.facultyAdvisors.length === 1
                ? "Faculty Advisor"
                : "Faculty Advisors"}
            </SectionTitle>
            <People>
              {edition.facultyAdvisors.map((node, index) => (
                <Link
                  to={generatePath(node.slug.current, "person")}
                  index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                  key={node._id}
                  className="custom"
                >
                  <Person>
                    <h3>
                      {generateNameWithHonorific(node.name, node.honorific)}
                    </h3>
                  </Person>
                </Link>
              ))}
            </People>
          </FadeIn>
        )}

        {edition.specialThanks.length > 0 && (
          <FadeIn direction="up" delay={750}>
            <SectionTitle>Special Thanks to</SectionTitle>
            <People>
              {edition.specialThanks.map((node, index) => (
                <Link
                  to={generatePath(node.slug.current, "person")}
                  index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                  key={node._id}
                  className="custom"
                >
                  <Person>
                    <h3>
                      {generateNameWithHonorific(node.name, node.honorific)}
                    </h3>
                  </Person>
                </Link>
              ))}
            </People>
          </FadeIn>
        )}
      </Edition>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityEdition(_id: { eq: $id }) {
      _id
      name
      status
      release(formatString: "MMMM D, YYYY")
      _rawDescription(resolveReferences: { maxDepth: 2 })
      editorsInChief {
        honorific
        name
        slug {
          current
        }
      }
      editorsInChief {
        _id
        honorific
        name
        slug {
          current
        }
      }
      editors {
        _id
        honorific
        name
        slug {
          current
        }
      }
      facultyAdvisors {
        _id
        honorific
        name
        slug {
          current
        }
      }
      specialThanks {
        _id
        honorific
        name
        slug {
          current
        }
      }
    }

    sanityArticles: allSanityArticle(
      filter: { edition: { _id: { eq: $id } } }
      sort: { fields: [sequence, title], order: ASC }
    ) {
      edges {
        node {
          _id
          title
          author {
            honorific
            name
          }
          slug {
            current
          }
          _rawContent(resolveReferences: { maxDepth: 1 })
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const Edition = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 1.5rem;
  transition: all 0.25s ease-in-out;

  @media only screen and (min-width: 767px) {
    padding-top: 0;
  }
`

const PageTitle = styled.h1`
  margin: 0.5rem 0 0 0;
`

const Subheader = styled.p`
  margin: 0.25rem 0 1.25rem 0;
  color: var(--gray);

  span {
    color: var(--page-color);
  }
`

const Description = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.15em;
`

const Note2 = styled.aside`
  --border-width: 2px;
  position: relative;
  margin: 0;
  padding: 1.5rem;
  background-color: rgba(var(--maroon-rgb), 0.065);
  border-radius: 10px;
  border: var(--border-width) solid var(--maroon);
  text-align: left;
  color: var(--page-color);

  @media only screen and (min-width: 767px) {
    margin: 0 -1.5rem;
  }

  p {
    margin: 0;
    line-height: 1.55;
    font-size: 0.925em;
  }
`

const Archived = styled.span`
  margin-left: 0.625em;
  background-color: var(--mid-white);
  padding: 0.25em 0.625em;
  border-radius: 5px;
  font-size: 0.9em;
`

const List = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: auto;
  grid-gap: 1.5rem;
`

const Articles = styled(List)`
  margin-top: 2rem;
`

const People = styled.div`
  display: block;
  margin-top: 0.75rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  > a.custom {
    transition: background-color 0.15s ease;
    border-radius: 10px;

    &:hover {
      background-color: var(--mid-white);
    }
  }

  @media only screen and (min-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Person = styled.div`
  --padding: 0.75em;
  padding: var(--padding);
  height: calc(100% - var(--padding) - 1rem);
  border-radius: 10px;
  border: 2px solid var(--mid-white);
  font-size: 1em;
`

const SectionTitle = styled.h2`
  margin-top: 2.5rem;
`
