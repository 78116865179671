import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

export default function ListElement({
  index,
  link,
  title,
  line2,
  extras1,
  extras2,
}) {
  return (
    <Link to={link} className="custom">
      <Element>
        <Number>{index}</Number>
        <Info>
          <Title>{title}</Title>
          <Line2>
            {extras1 && <Extras>{extras1}</Extras>}
            {line2}
            {extras2 && <Extras>{extras2}</Extras>}
          </Line2>
        </Info>
      </Element>
    </Link>
  )
}

const Element = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(100% - (1.15rem * 2));

  border: 2px solid var(--mid-white);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  transition: background-color 0.15s ease;

  &:hover {
    background-color: var(--mid-white);
  }

  a:focus-visible & {
    border-color: var(--gold);
    outline: none;
  }
`

const Number = styled.div`
  margin-right: 1.5rem;
  color: var(--gray);
  font-size: 2.75rem;
`

const Info = styled.div``

const Title = styled.h2`
  margin-bottom: 0.25rem;
`

const Line2 = styled.p``

const Extras = styled.span`
  color: var(--gray);
`
